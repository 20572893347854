import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private alertController: AlertController, public toastController: ToastController) { }
  
  async presentAlert(header, message, handler = null) {
    var buttonText = 'OK';
    const alert = await this.alertController.create({
      header: 'prim says:',
      cssClass: 'custom-class',
      subHeader: header,
      message: message,
      buttons: [
        {
          text: buttonText,
          handler: handler
        }
      ]
    });

    await alert.present();
  }  
  
  async presentToast(message, icon?, color?, time = 2000) {
    const toast = await this.toastController.create({
      message: message,
      icon: icon,
      color: color,
      duration: time,
      position: 'top',
    });
    toast.present();
  }
}
