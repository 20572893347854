import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'preflight',    
    loadChildren: () => import('./pages/preflight/preflight.module').then(m => m.PreflightPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./modules/appointments/appointments.module').then(m => m.AppointmentsModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'diets',
    loadChildren: () => import('./modules/diet/diet.module').then(m => m.DietModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'appointments',    
    loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule)
  }, 
  {
    path: 'user',    
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  }, 
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule)
  },
  {
    path: 'auth',    
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',    
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
