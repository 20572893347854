import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { JwtService } from 'src/app/core/services/jwt.service';
import { ForgotPassword } from '../models/forgot-password';
import { SignUpJourney } from '../models/sign-up-journey';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public enableCoachAPISwitch = new BehaviorSubject<boolean>(false);

  sign_up_journey: SignUpJourney = {
    reason_of_joining: '',
    main_goals: '',
    height: 0,
    diet_tags: '',
    height_unit: '',
    goal_weight: 0,
    goal_weight_unit: '',
    gender: '',
    date_of_birth: '',
    weight: 0,
    weight_unit: '',
  };

  public loggedInSubject = new BehaviorSubject<boolean>(false);
  public clientDetailsByToken$ = new BehaviorSubject<any>({});

  constructor(private httpClient: HttpClient, private jwtService: JwtService) {}

  get $isAuthenticated(): Observable<boolean> {
    return this.loggedInSubject.asObservable().pipe(distinctUntilChanged());
  }

  setJourney(sign_up_journey: SignUpJourney) {
    this.sign_up_journey = sign_up_journey;
  }

  getJourney() {
    return this.sign_up_journey;
  }

  // BehaviourSubject should be used for confirming current status of user

  signUp(formData): Observable<any> {
    return this.httpClient
      .post('/api/auth/register', formData)
      .pipe((res) => res);
  }

  validateToken(token): Observable<any> {
    return this.httpClient
      .post('/api/auth/validatetoken', token)
      .pipe((res) => res);
  }

  async login(credentials): Promise<any> {
    const result: any = await this.httpClient
      .post('/api/auth/token', credentials)
      .toPromise();
    if (result.token) {
      await this.jwtService.saveToken(result.token);
      this.getClientDetailsFromToken(result.token);
      //localStorage.setItem('token', result.token);
    } else {
      Promise.reject(result);
    }

    return Promise.resolve(result);
  }

  isGuestUser(): Observable<boolean> {
    let isGuest = new BehaviorSubject<boolean>(false);
    var token = localStorage.getItem('token');
    if (token == null || token == undefined) {
      isGuest.next(true);
    } else {
      var guestValue = JSON.parse(atob(token.split('.')[1])).is_guest;
      if (guestValue == '1') {
        isGuest.next(true);
      }
    }
    return isGuest;
  }

  async isGuest(): Promise<boolean> {
    let isGuest = false;
    const token = await this.jwtService.getToken();
    if (token == null || token == undefined) {
      isGuest = true;
    } else {
      var guestValue = JSON.parse(atob(token.split('.')[1])).is_guest;
      if (guestValue == '1') {
        isGuest = true;
      }
    }
    return Promise.resolve(isGuest);
  }

  signOut() {
    return this.jwtService.destroyToken();
  }

  forgotPassword(formData: any): Observable<any> {
    return this.httpClient
      .post('/api/auth/forgot_password', formData)
      .pipe((res) => res);
  }

  resetPassword() {}

  async activate(user_id: string, activation_code: string): Promise<any> {
    const result: any = await this.httpClient
      .post(`/api/auth/activate`, {
        user_id: user_id,
        activation_code: activation_code,
      })
      .toPromise();

    if (result?.data?.token) {
      await this.jwtService.saveToken(result?.data?.token);
      //localStorage.setItem('token', result.token);
      this.loggedInSubject.next(true);
    } else {
      Promise.reject(result);
    }

    return Promise.resolve(result);
  }

  async forgotPasswordComplete(forgotPassword: ForgotPassword): Promise<any> {
    const result: any = await this.httpClient
      .post(`/api/auth/forgot_password_complete`, {
        identity: forgotPassword.identity,
        activation_code: forgotPassword.forgot_password_code,
        new_password: forgotPassword.new_password,
        confirm_password: forgotPassword.confirm_password,
      })
      .toPromise();

    if (result?.data?.token) {
      await this.jwtService.saveToken(result?.data?.token);
      //localStorage.setItem('token', result.token);
      this.loggedInSubject.next(true);
    } else {
      Promise.reject(result);
    }

    return Promise.resolve(result);
  }

  updateProfile(profileData: any): Observable<any> {
    return this.httpClient
      .post('/api/users/profile', profileData)
      .pipe((res) => res);
  }

  getUser(): any {
    var token = localStorage.getItem('token');
    if (token == null || token == undefined) {
      return null;
    } else {
      var userDetails = JSON.parse(atob(token.split('.')[1])).is_guest;
      return userDetails;
    }
  }

  getClientDetailsFromToken(token) {
    var decodeToken = JSON.parse(atob(token.split('.')[1]));
    this.clientDetailsByToken$.next(decodeToken);
    this.loggedInSubject.next(true);
  }

  async getUserDetailsFromToken(): Promise<any> {
    let token = await this.jwtService.getToken();
    if (token) {
      var decodeToken = JSON.parse(atob(token.split('.')[1]));
      this.loggedInSubject.next(true);
      return Promise.resolve(decodeToken);
    }
    this.loggedInSubject.next(false);
    return Promise.reject(null);
  }

  enableCoachAPI(value: boolean) {
    this.enableCoachAPISwitch.next(value);
  }

  validateReferrerCode(referrer_code: any): Observable<any> {
    return this.httpClient
      .post('/api/auth/validate_referrer_code', referrer_code)
      .pipe((res) => res);
  }

  sendOtp(formData): Observable<any> {
    return this.httpClient.post('/api/Auth/sendotp', formData);
  }

  verifyOtp(formData): Observable<any> {
    return this.httpClient.post('/api/Auth/verifyotp', formData);
  }

  async verifyOtpLogin(formData): Promise<any> {
    // return this.httpClient.post('/api/Auth/validateOtpAndLogin', formData)

    const result: any = await this.httpClient
      .post('/api/Auth/validateOtpAndLogin', formData)
      .toPromise();
    if (result.token) {
      await this.jwtService.saveToken(result.token);
      this.getClientDetailsFromToken(result.token);
    } else {
      Promise.reject(result);
    }

    return Promise.resolve(result);
  }
}
