import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(
    key:
      | 'token'
      | 'plan'
      | 'name'
      | 'diet_journey'
      | 'sign_up_value'
      | 'currency'
      | 'applied_discount',
    value: any
  ) {
    await this._storage?.set(key, value);
  }

  public async get(
    key:
      | 'token'
      | 'plan'
      | 'name'
      | 'diet_journey'
      | 'sign_up_value'
      | 'currency'
      | 'applied_discount'
  ): Promise<any> {
    this._storage = await this.storage.create();
    return await this._storage?.get(key);
  }

  public async remove(
    key: 'token' | 'plan' | 'diet_journey' | 'applied_discount'
  ) {
    this._storage = await this.storage.create();
    return this._storage?.remove(key);
  }

  public async clear() {
    this._storage = await this.storage.create();
    return this._storage?.clear();
  }
}
