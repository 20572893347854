import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';

import { JwtService } from '../services/jwt.service';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig: any = {};
    if (req.url !== '/api/DynamicForms/upload_documents') {
      headersConfig['Content-Type'] = 'application/json';
      headersConfig['Accept'] = 'application/json';
    }
    return from(this.jwtService.getToken()).pipe(
      switchMap((token) => {
        if (token) {
          if(!req.url.includes('sendMessageWithHttp')) {
            this.authService.getClientDetailsFromToken(token);
            //this.authService.loggedInSubject.next(true);

            headersConfig['Authorization'] = `${token}`;
            headersConfig['KAuthorization'] = `${token}`;
          }
        }
        const enableCoachAPI = this.authService.enableCoachAPISwitch;
        let url = environment.api.url;
        if (enableCoachAPI.value) {
          url = environment.api.coach_url;
          this.authService.enableCoachAPI(false);
        }
        let request = null;
        if(req.url.includes('sendMessageWithHttp')) {
          request = req.clone({
            setHeaders: headersConfig,
            url: req.url,
          });
        } else {
          request = req.clone({
            setHeaders: headersConfig,
            url: `${url}${req.url}`,
          });
        }
        return next.handle(request);
      })
    );
  }
}
