import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { Subject } from 'rxjs';

@Injectable()
export class JwtService {
  private _onToken = new Subject();
  onToken = this._onToken.asObservable();

  constructor(private storageService: StorageService) {}

  async getToken(): Promise<any> {
    const resp = await this.storageService?.get('token');
    return resp;
  }

  async saveToken(token: String) {
    this._onToken.next('tokenSet');
    return await this.storageService.set('token', token);
  }

  async destroyToken() {
    this._onToken.next('tokenDeleted');
    return await this.storageService.clear();
  }

  // async getTokenFromStorag(): Observable<any>{
  //   const resp = await this.storageService?.get('token');

  // }
}
