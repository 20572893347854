import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyUpdateService {
  private _open = new Subject();
  private _change = new Subject();
  private _setUserPrefrence = new Subject();
  open = this._open.asObservable();
  change = this._change.asObservable();
  setUserPrefrence = this._setUserPrefrence.asObservable();

  openMenu() {
    this._open.next('open');
  }

  onChange(currency) {
    this._change.next(currency);
  }

  constructor(private httpClient: HttpClient) {}

  getCurrencyClass(currency): string {
    switch (currency) {
      case 'INR':
        return 'fa-indian-rupee-sign';
      case 'USD':
        return 'fa-dollar-sign';
      case 'EUR':
        return 'fa-euro-sign';
    }
  }

  getCurrencyList(): Observable<any> {
    return this.httpClient.get(`/api/shop/getCurrencies`).pipe((res) => res);
  }

  setCurrency() {
    this._setUserPrefrence.next('set');
  }
}
