import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { CurrencyUpdateService } from '../currency-update.service';
import { StorageService } from 'src/app/shared/services/storage/storage.service';
import { Subscription, forkJoin, noop } from 'rxjs';
import { UserService } from 'src/app/user/services/user.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'currency-change',
  templateUrl: './currency-change.component.html',
  styleUrls: ['./currency-change.component.scss'],
})
export class CurrencyChangeComponent implements OnInit {
  openMenuSubcription: Subscription;
  currencyList = [];
  onLogin: boolean;

  constructor(
    private actionsheetCtrl: ActionSheetController,
    private currenyService: CurrencyUpdateService,
    private storageService: StorageService,
    private userService: UserService,
    private notificationService: NotificationService,
    private jwtService: JwtService
  ) {
    this.openMenuSubcription = this.currenyService.open.subscribe((res) => {
      this.openMenu();
    });

    // this.currenyService.setUserPrefrence.subscribe((data) => {
    //   this.getUserPreference().then((res) => {
    //     noop();
    //   });
    // });

    this.jwtService.onToken.pipe(delay(1000)).subscribe((res) => {
      if (res === 'tokenSet') {
        this.onLogin = true;
      } else if (res === 'tokenDeleted') {
        this.onLogin = false;
      }
      // this.getUserPreference().then((res) => {
      //   noop();
      // });
    });
    this.jwtService.getToken().then((res) => {
      if (res) {
        this.onLogin = true;
      } else {
        this.onLogin = false;
      }
      // this.getUserPreference().then((res) => {
      //   noop();
      // });
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.openMenuSubcription.unsubscribe();
  }

  getUserPreference(): Promise<any> {
    const userPreferenceApi = this.userService.getUserPreference();
    const currencyListApi = this.currenyService.getCurrencyList();
    const apiList = this.onLogin
      ? [currencyListApi, userPreferenceApi]
      : [currencyListApi];
    return new Promise((resolve, reject) => {
      forkJoin(apiList).subscribe(
        ([currencyList, user_preferences]) => {
          if (currencyList && currencyList.data.length > 0) {
            this.currencyList = [];
            currencyList.data.forEach((cur) => {
              let temp = {
                text: cur.currency_name,
                icon: cur.currency_code,
                data: `${cur.currency_code}@${cur.currency_symbol}@${cur.coin_conversion_value}`,
                cssClass: 'currencyBtn',
              };
              this.currencyList.push(temp);
            });
            this.currencyList.push({
              text: 'Cancel',
              role: 'cancel',
            });
          }
          if (user_preferences) {
            const userPrefCurrencyObj = user_preferences['data'].find(
              (item) => item.preference_key === 'currency'
            );
            const userCurrencyObj = currencyList.data.find(
              (item) =>
                item.currency_code.toLowerCase() ===
                userPrefCurrencyObj.preference_value.toLowerCase()
            );
            const currencyString = `${userCurrencyObj.currency_code.toUpperCase()}@${
              userCurrencyObj.currency_symbol
            }@${userCurrencyObj.coin_conversion_value}`;
            this.storageService.set('currency', currencyString);
            setTimeout(() => {
              this.currenyService.onChange(currencyString);
            }, 500);
          }

          resolve(true);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  async openMenu() {
    if (this.currencyList.length === 0) {
      await this.getUserPreference();
    }
    const actionSheet = await this.actionsheetCtrl.create({
      header: 'Select currency',
      buttons: this.currencyList,
    });
    await actionSheet.present();
    const { role, data } = await actionSheet.onDidDismiss();
    if (role != 'cancel' && data) {
      this.updateCurrency(data);
    }
  }

  updateCurrency(currencyObj) {
    if (this.onLogin) {
      const currencyArr = currencyObj.split('@');
      const reqObj = {
        preference_key: 'currency',
        preference_value: currencyArr[0],
      };
      this.userService.postUserPreference(reqObj).subscribe((res) => {
        this.storageService.set('currency', currencyObj);
        this.currenyService.onChange(currencyObj);
        this.notificationService.presentToast(
          'currency set successfully',
          '',
          'success'
        );
      });
    } else {
      this.storageService.set('currency', currencyObj);
      this.currenyService.onChange(currencyObj);
      this.notificationService.presentToast(
        'currency set successfully',
        '',
        'success'
      );
    }
  }
}
