import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserPreference } from 'src/app/auth/models/user-preference';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // public claim_free_diet_eligibility$ = new BehaviorSubject<any>({});

  constructor(private httpClient: HttpClient) {}

  getCurrentProgram() {
    return this.httpClient
      .get('/api/Users/current_packages')
      .pipe((res) => res);
  }

  saveDeviceToken(obj) {
    return this.httpClient
      .post('/api/Notifications/user_device_token', obj)
      .pipe((res) => res);
  }

  deleteDeviceToken(obj) {
    return this.httpClient
      .post('/api/Notifications/delete_device_token', obj)
      .pipe((res) => res);
  }

  checkDeviceToken() {
    return this.httpClient.get(`/api/Notifications/device_tokens_by_user_id`);
  }

  getProfile(): Observable<any> {
    return this.httpClient.get('/api/users/client_details').pipe((res) => res);
  }

  getBMI() {}

  freeDietEligibilityCheck() {
    return this.httpClient.get('/api/Users/check_free_diet_eligibility');
  }

  freeDietEligibility() {
    return this.httpClient.get('/api/Users/check_free_diet_eligibility');
    // this.httpClient
    //   .get('/api/Users/check_free_diet_eligibility')
    //   .subscribe((res: any) => {
    //     this.claim_free_diet_eligibility$.next(res.isValid);
    //   }, (err) => {
    //     this.claim_free_diet_eligibility$.next(err.error.isValid);
    //     console.log(err);
    //   });
  }

  getCounsellorOfUser() {}

  getCoins() {}

  getOrders() {}

  getInvoices() {}

  downloadInvoice() {}

  updateProfile() {}

  updateGoalWeightAndCurrentWeight() {}

  updateDietTags() {}

  getReferrerCode() {
    return this.httpClient.get('/api/users/referral_codes');
  }

  checkProfileCompletion() {
    return this.httpClient.get('/api/users/check_client_profile_completion');
  }

  updatePhoneNumber(phone): Observable<any> {
    return this.httpClient
      .post('/api/Users/addPhoneNumber', phone)
      .pipe((res) => res);
  }

  updateWeight(weight): Observable<any> {
    return this.httpClient
      .post('/api/users/update_weight', weight)
      .pipe((res) => res);
  }

  getUserPreference() {
    return this.httpClient
      .get('/api/users/user_preferences')
      .pipe((res) => res);
  }

  postUserPreference(preferenceObj) {
    return this.httpClient
      .post('/api/users/user_preferences', preferenceObj)
      .pipe((res) => res);
  }

  getUserNotificationsList(): Observable<any> {
    return this.httpClient
      .get('/api/notifications/notifications')
      .pipe((res) => res);
  }

  updateNotifications(object): Observable<any> {
    return this.httpClient
      .post('/api/notifications/update_notifications', object)
      .pipe((res) => res);
  }

  getUserPreferenceDetail(): Observable<any> {
    return this.getUserPreference().pipe(
      map((res: IUserPreference) => {
        if (res.isValid && res.data.length > 0) {
          return res.data[0];
        }
        return null;
      })
    );
  }

  getCounsellorDeviceToken() {
    return this.httpClient
      .get('/api/Chat/admin_user_token')
      .pipe((res) => res);
  }

}
